import React, { useState, useEffect } from "react";
import styled from "styled-components";
// import units from "design-units";
// @ts-ignore
import { TailSpin } from "svg-loaders-react";

// const LOGO_URL = "https://raw.githubusercontent.com/addy999/Spaceshots-Core/master/Logo/draft1.png";

const Loader = () => {
  const [msgIdx, setMsgIdx] = useState(0);

  const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    p {
      width: fit-content;
      margin: auto;
      color: white;
    }
    svg {
      width: inherit;
      margin: auto;
      margin-bottom: 10px;
    }

    div {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
    }
  `;

  const setNextMsg = () => {
    if (msgIdx + 1 < messages.length) {
      setMsgIdx(msgIdx + 1);
    } else {
      setMsgIdx(0);
    }
  };

  useEffect(() => {
    const id = setInterval(() => {
      setNextMsg();
    }, 3000);

    return () => {
      clearInterval(id);
    };
  });

  const messages = ["Downloading spacecraft blueprints", "Charting course"];

  return (
    <Wrapper>
      <div>
        <TailSpin />
        <p>{messages[msgIdx] + "..."}</p>
      </div>
    </Wrapper>
  );
};

export default Loader;
