import React, { useState, useEffect, useRef } from "react";
import loadGameFiles, { startGame, stepForward, getStatus } from "../api";
import Loader from "./Loader";
import styled from "styled-components";
import View from "./Viewport";

const FPS = 40;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background: rgb(228, 126, 48);
  // border: red 1px solid;
  overflow: hidden;
`;

// function readCmd() {
//   // @ts-ignore
//   return parseInt(document.body.getAttribute("key"));
// }

// function setCmd(cmd: number) {
//   document.body.setAttribute("key", String(cmd));
// }

const setKeyListeners = (ref: any, setKeyPressed: any, toggleGameLoop: any) => {
  // @ts-ignore
  document.body.onkeydown = (e: React.SyntheticEvent) => {
    // @ts-ignore
    var key_code = e.which || e.keyCode;
    var command = 0;

    if (key_code === 37 || key_code === 65) {
      command = 2;
    } //left arrow / a
    if (key_code === 38 || key_code === 87) {
      command = 1;
    } //Up arrow / w
    if (key_code === 39 || key_code === 68) {
      command = 4;
    } //right arrow / d
    if (key_code === 40 || key_code === 83) {
      command = 3;
    } //down arrow / s

    if (key_code === 32) {
      // Pause / resume game on Spacebar
      toggleGameLoop();
    }

    setKeyPressed(command);
  };

  // @ts-ignore
  document.body.onkeyup = (e: React.SyntheticEvent) => {
    // @ts-ignore
    var key_code = e.which || e.keyCode;
    if (
      (37 <= key_code && key_code <= 40) ||
      key_code === 65 ||
      key_code === 87 ||
      key_code === 68 ||
      key_code === 83
    ) {
      setKeyPressed(0);
    }
  };
};

function Game() {
  const [filesLoaded, setLoaded] = useState(false);
  const [gameStatus, setStatus] = useState({});
  const [gameStarted, setGame] = useState(false);
  const gameLoopActive = useRef(false);
  const listenerId = useRef(null);
  const keyPressed = useRef(0);
  const gameRef = useRef();

  // On component mount, start loading game files
  useEffect(() => {
    loadGameFiles(setLoaded);
  });

  const _startGameLoop = () => {
    console.log("Starting game loop...");
    setGame(true);
    gameLoopActive.current = true;

    const id = setInterval(() => {
      stepForward(keyPressed.current).then(() => {
        setStatus(getStatus());
      });
    }, 1 / FPS);

    // @ts-expect-error
    listenerId.current = id;
  };

  const _pauseGameLoop = () => {
    gameLoopActive.current = false;
    // @ts-ignore
    clearInterval(listenerId.current);
  };

  const _toggleGameLoop = () => {
    if (gameLoopActive.current) {
      _pauseGameLoop();
    } else {
      _startGameLoop();
    }
  };

  return (
    // @ts-ignore
    <Wrapper ref={gameRef}>
      {gameStarted ? (
        <View gameStatus={gameStatus} />
      ) : filesLoaded ? (
        <button
          onClick={() => {
            startGame(
              // @ts-ignore
              gameRef.current.getBoundingClientRect().width,
              // @ts-ignore
              gameRef.current.getBoundingClientRect().height,
              FPS * 2
            ).then(() => {
              console.log("Created!");
              // Begin listening for any key presses
              // they'll only be sent while the game loop is running, so we just store them for now.
              setKeyListeners(
                gameRef,
                (cmd: number) => {
                  keyPressed.current = cmd;
                },
                () => {
                  _toggleGameLoop();
                }
              );
              setStatus(getStatus()); // set initial status
              _startGameLoop();
            });
          }}
        >
          Create game
        </button>
      ) : (
        <Loader />
      )}
    </Wrapper>
  );
}

export default Game;
