import units from "design-units";
import styled from "styled-components";
import "./index.css";

const AssetWrapper = styled.div<{
  width: number;
  height: number;
  x: number;
  y: number;
  rot?: number;
  trans?: boolean;
  scale?: number;
}>`
  position: absolute;
  display: block;
  ${({ width, height, x, y, rot, trans, scale }) =>
    units({
      width: `${width}px`,
      height: `${height}px`,
      top: `${y - height / 2}px`,
      left: `${x - width / 2}px`,
      transform: `scale(${scale ? scale : 1}) rotate(${rot ? -1 * rot : 0}rad)`,
      background: !trans && "black",
    })}
`;

export const Background = styled.div<{ background: string }>`
  // position: absolute;
  // top: 0;
  // left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;

  ${({ background }) =>
    units({
      background: background,
    })}
`;

export const Thrust = styled.img<{
  rot: number;
  x?: number;
  y: number;
  scale?: number;
  on: boolean;
}>`
  position: absolute;
  display: block;
  width: 25%;
  height: 70%;
  z-index: 1;
  left: 50%;
  ${({ rot, x, y, scale, on }) =>
    units({
      transform: `scale(${scale ? scale : 1}) translateX(${
        x ? x : -50
      }%) rotate(${rot}rad)`,
      top: `${y}px`,
      display: !on && "none",
      // left: `${x}px`,
    })}
`;

export const SpriteImg = styled.img<{ rot?: number }>`
    width: 100%;
    height 100%;
    position: absolute;
    z-index: 10;
    ${({ rot }) =>
      units({
        transform: `rotate(${rot}rad)`,
      })}
`;

export { AssetWrapper };
