const loadGameFiles = (setLoaded: any) => {
  if (window.pyodide) {
    return null;
  }

  const script = document.createElement("script");
  script.src = "https://cdn.jsdelivr.net/pyodide/v0.16.1/full/pyodide.js";

  //@ts-ignore
  script.onload = () => {
    window.languagePluginUrl = "https://cdn.jsdelivr.net/pyodide/v0.16.1/full/";

    window.languagePluginLoader
      .then(() => {
        return window.pyodide.loadPackage(["micropip"]);
      })
      .then(() => {
        window.pyodide
          .runPythonAsync(
            `
                import micropip
                micropip.install('spaceshots')
            `
          )
          .then(() =>
            window.pyodide.runPythonAsync(`
                import spaceshots
                print("Loaded Spaceshots Core.")
            `)
          )
          .then(() => {
            setLoaded(true);
          });
      });
  };

  document.body.appendChild(script);
};

export const startGame = (screenX: number, screenY: number, FPS: number) => {
  console.log("> Starting game w/ width  height:", screenX, screenY);
  return window.pyodide.runPythonAsync(
    "manager = spaceshots.Manager(" +
      screenX +
      "," +
      screenY +
      ", fps=" +
      FPS +
      ")"
  );
};

export const stepForward = (step: number) => {
  // console.log("Stepping w/", step);
  return window.pyodide.runPythonAsync("manager.step(" + step + ")");
};

export const getStatus = () => {
  return {
    ...window.pyodide.globals.manager.get_details(),
    ...window.pyodide.globals.manager.status,
  };
};

export default loadGameFiles;
