import React, { useState, useEffect, useRef } from "react";
import units from "design-units";
import styled from "styled-components";
import { SC, Planet, Background } from "../Assets";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  background: white;
`;

const View = ({ gameStatus }: any) => {
  const { sc, scene, planets } = gameStatus;
  return (
    <Wrapper>
      <SC
        width={sc.size[0]}
        height={sc.size[1]}
        x={sc.pos[0]}
        y={scene.size[1] - sc.pos[1]}
        rotation={sc.rot}
        thrust={sc.thrust}
      />
      {planets.map((p: any) => (
        <Planet
          width={p.radius}
          height={p.radius}
          x={p.pos[0]}
          radius={p.radius}
          y={scene.size[1] - p.pos[1]}
        />
      ))}
      <Background background={"linear-gradient(to right, #232526, #414345)"} />
    </Wrapper>
  );
};

export default View;
