import React, { useEffect, useState } from "react";
import Game from "./components/Game";
import styled from "styled-components";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;

  #load {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
function App() {
  const [gameLoaded, loadGame] = useState(false);

  return (
    <Wrapper>
      {gameLoaded ? (
        <Game />
      ) : (
        <button id="load" onClick={() => loadGame(true)}>
          Load game
        </button>
      )}
    </Wrapper>
  );
}

export default App;
