import React from "react";
import SC_SPRITE_0 from "./Sprites/0.png";
import Flames from "./Sprites/flame.svg";
import { AssetWrapper, SpriteImg, Thrust, Background } from "./components";

export { Background };

export const Planet = ({ width, height, x, y, radius }: any) => {
  return (
    <AssetWrapper
      width={width}
      height={height}
      x={x}
      y={y}
      scale={radius / 40}
      style={{ borderRadius: "100000px", background: "white" }}
    ></AssetWrapper>
  );
};

const SC_SPRITES = [
  {
    src: SC_SPRITE_0,
    rotOffset: -Math.PI / 4, // rad
    rearThurstOffset: (width: number, height: number) => -height / 3,
    frontThurstOffset: (width: number, height: number) => height / 2.5,
    minusXLoc: (width: number, height: number) => [width * 2.5, 0],
    plusXLoc: (width: number, height: number) => [-width * 4.5, 0],
  },
];

export const SC = ({ width, height, rotation, x, y, thrust }: any) => {
  const IMG = SC_SPRITES[0];
  const SCALE_FACTOR = 1.5;
  return (
    <AssetWrapper
      width={width}
      height={height}
      x={x}
      y={y}
      scale={SCALE_FACTOR}
      rot={rotation}
      trans
    >
      <SpriteImg src={IMG.src} alt="Spacecraft sprite" rot={IMG.rotOffset} />
      <Thrust
        src={Flames}
        y={height + IMG.rearThurstOffset(width, height)}
        rot={Math.PI}
        on={thrust.on && thrust.dir === "+y"}
      />
      <Thrust
        src={Flames}
        y={-height + IMG.frontThurstOffset(width, height)}
        rot={0}
        scale={0.75}
        on={thrust.on && thrust.dir === "-y"}
      />
      <Thrust
        src={Flames}
        x={IMG.minusXLoc(width, height)[0]}
        y={IMG.minusXLoc(width, height)[1]}
        rot={Math.PI / 2}
        scale={0.75}
        on={thrust.on && thrust.dir === "-x"}
      />
      <Thrust
        src={Flames}
        x={IMG.plusXLoc(width, height)[0]}
        y={IMG.plusXLoc(width, height)[1]}
        rot={-Math.PI / 2}
        scale={0.75}
        on={thrust.on && thrust.dir === "+x"}
      />
    </AssetWrapper>
  );
};
